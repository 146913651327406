export default [
  {
    header: 'POS',
    action: 'read',
    resource: 'client',
  },
  {
    title: 'Sale',
    route: 'pos-sale-new',
    icon: 'ShoppingCartIcon',
    action: 'read',
    resource: 'sale',
  },
  {
    title: 'Transactions',
    route: 'pos-sale-list',
    icon: 'ListIcon',
    action: 'read',
    resource: 'sale',
  },
  {
    title: 'Clients',
    route: 'pos-client-list',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'client',
  },
  {
    header: 'Setup',
    action: 'read',
    resource: 'employee',
  },
  {
    title: 'Branches',
    route: 'branch-list',
    icon: 'DatabaseIcon',
    action: 'read',
    resource: 'branch',
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    action: 'read',
    resource: 'employee',
    children: [
      {
        title: 'Employees',
        route: { name: 'employee-list' },
        action: 'read',
        resource: 'employee',
      },
      {
        title: 'Audit Logs',
        route: { name: 'audit-log-list' },
        action: 'audit',
        resource: 'all',
      },
    ],
  },
  {
    title: 'Product',
    icon: 'PackageIcon',
    children: [
      {
        title: 'Categories',
        route: 'category-list',
        action: 'read',
        resource: 'category',
      },
      {
        title: 'Units',
        route: { name: 'unit-list' },
        action: 'read',
        resource: 'unit',
      },
      {
        title: 'Items',
        route: { name: 'item-list' },
        action: 'read',
        resource: 'item',
      },
    ],
  },
  {
    title: 'Reasons',
    route: 'reason-list',
    icon: 'InfoIcon',
    action: 'read',
    resource: 'reason',
  },
  {
    title: 'Reports',
    icon: 'BarChart2Icon',
    action: 'read',
    resource: 'report',
    children: [
      {
        title: 'Sale Revenues',
        route: 'report-sale-revenue',
        action: 'read',
        resource: 'report_sale_revenue',
      },
      {
        title: 'Sold Items',
        route: { name: 'report-sale-item' },
        action: 'read',
        resource: 'report_sale_item',
      },
      {
        title: 'Inventory Items',
        route: { name: 'report-inventory-item' },
        action: 'read',
        resource: 'report_inventory_item',
      },
    ],
  },
  {
    header: 'Store',
    action: 'read',
    resource: 'inventory',
  },
  {
    title: 'Inventory',
    route: 'inventory-list',
    icon: 'ArchiveIcon',
    action: 'read',
    resource: 'inventory',
  },
  {
    title: 'Receipts',
    route: 'receipt-list',
    icon: 'DownloadIcon',
    action: 'read',
    resource: 'receipt',
  },
  {
    title: 'Issues',
    route: 'issue-list',
    icon: 'UploadIcon',
    action: 'read',
    resource: 'issue',
  },
  {
    title: 'Adjustments',
    route: 'adjustment-list',
    icon: 'SlidersIcon',
    action: 'read',
    resource: 'adjustment',
  },
  {
    header: 'Accounting',
    action: 'read',
    resource: 'cash_book',
  },
  {
    title: 'Ledgers',
    route: 'ledger-list',
    icon: 'BookmarkIcon',
    action: 'read',
    resource: 'ledger',
  },
  {
    title: 'Cash Book',
    route: 'cashbook-list',
    icon: 'BookOpenIcon',
    action: 'read',
    resource: 'cash_book',
  },
]
